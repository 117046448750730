import Axios from 'axios';

  export default {
    data() {
      return {
        hirings: [],
        chart: {
          height: 150,
          data: {
            labels: ["JAN", "MAR", "MAI", "JUL", "SET", "NOV"],
            datasets: [
              {
                label: 'Novos projetos',
                backgroundColor: '#7553E2',
                data: [Math.random(), Math.random(), Math.random(), Math.random(),Math.random(), Math.random()]
              }, {
                label: 'Projetos antigos',
                backgroundColor: '#74E7D0',
                data: [Math.random(), Math.random(), Math.random(), Math.random(),Math.random(), Math.random()]
              }
            ]
          },
          options: {
            responsive: true,
          },
        }
      }
    },
    mounted() {

      let me = this.$store.state.me;
      const TOKEN = this.$store.state.token;

      if (this.$store.state.role == 'professional') this.$router.push({ path: '/dashboard/professional/historic' })

      axios
        .get('/api/v1/hirings?provider=' + me.id, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          }
        })
        .then(response => {
          this.hirings = response.data.data;
      });

      console.log('Component mounted.')
    },

    computed: {
      active() {
        // status 1, 2, 3, ou 4
        // aberto, selecão, ativo mas não cancelado ou encerrado
        return this.hirings.filter(hiring => {
          return hiring.status != 5;
        });
      },
    },

    methods: {

      sumApprovedValues: function (hirings) {
        var sum = 0;
        hirings.forEach((hiring, index) => {
          sum += hiring.approved_value;
        });
        return sum;
      },

      toCurrency(val) {
        return (val/100).toLocaleString("pt-BR", {style: "currency", currency: "BRL", minimumFractionDigits: 2});
      },
    },


  }
